import { template as template_00d580d26e5c43a28dd05a0de9e556d6 } from "@ember/template-compiler";
const FKLabel = template_00d580d26e5c43a28dd05a0de9e556d6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
