import { template as template_721d6c95653548dd975dd13f3deb32cf } from "@ember/template-compiler";
const FKControlMenuContainer = template_721d6c95653548dd975dd13f3deb32cf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
