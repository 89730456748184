import { template as template_c2cab6db8f0d449d935baf91fa8dc9e1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c2cab6db8f0d449d935baf91fa8dc9e1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
