import { template as template_38ed36da4b3d408f82b62a8ac59dfc39 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_38ed36da4b3d408f82b62a8ac59dfc39(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
